/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import styled from 'styled-components';

import { mediaQueries } from 'shared/helpers/styled-components/mediaQueries';
import Picture from 'shared/components/presentational/Picture';
import { container } from 'shared/constants/mixins';
import Icon from 'shared/components/presentational/Icons/Icon';
import BaseSlider from 'shared/components/presentational/Slider/Slider';

export const Wrapper = styled.section`
  ${container};
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  background: var(--surface-background);
`;

export const ServicesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m);

  ${mediaQueries.fromTablet`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
    `}
`;

export const SlideWrapper = styled.div`
  padding: 0 var(--spacing-xs);
  height: 100%;
`;

export const CardWrapper = styled.div<{ link: boolean }>`
  background: var(--surface-primary);
  border-radius: var(--border-radius-m);
  padding: var(--spacing-m);
  height: 100%;

  &:hover {
    background: ${props =>
      props.link ? 'var(--surface-tertiary)' : 'var(--surface-primary)'};
  }
  display: flex !important;
  flex-direction: column;
  gap: var(--spacing-m);
`;

export const ServicePicture = styled(Picture)`
  height: 112px;
  width: 100%;
  object-fit: contain;
`;

export const ServicePicturePlaceholder = styled.div`
  height: 112px;
  width: 100%;
`;

export const Description = styled.div`
  color: var(--text-light);
  font-size: var(--text-size-base);
  p {
    margin: 0;
  }
`;

export const InformationWrapper = styled.div`
  display: flex;
  gap: calc(var(--spacing-s) / 2);
  color: var(--text-default);
`;

export const CustomIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--spacing-s);
  width: var(--spacing-s);
  flex-shrink: 0;
`;

export const Information = styled.span`
  font-size: var(--text-size-xs);
`;

export const Slider = styled(BaseSlider)`
  ${mediaQueries.fromTablet`
  
  margin: 0 -8px;
  
  `}
`;
